<template>
    <v-app>
        <AppBar :key="count" />
        <MainView />
    </v-app>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        AppBar: () => import("@/components/layout/AppBar2.vue"),
        MainView: () => import("@/components/layout/MainView2.vue")
    }
})
export default class Index extends Vue {
    count = 0;
    mounted() {
        this.$root.$on("reloadAppBar", () => {
            this.count++;
        });
    }
}
</script>
